/* eslint-disable camelcase */
const initialState = {
  profile: null,
  isSignedIn: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'USER_SIGN_OUT': {
      return { ...initialState };
    }
    case 'USER_FINISH_ONBOARDING': {
      return {
        ...state,
        profile: {
          ...state.profile,
          isOnboarded: true,
        },
      };
    }
    case 'USER_SET_SESSION': {
      const {
        username,
        avatar,
        is_private, // eslint-disable-line
        about,
        location,
        url,
        is_verified, // eslint-disable-line
        is_onboarded, // eslint-disable-line
        following,
        bookmarks,
        branding,
        email,
        id,
        gender,
        provider,
        subscription,
        // eslint-disable-next-line camelcase
        current_contest,
        // eslint-disable-next-line camelcase
        unique_name,
      } = action.data;
      return {
        ...state,
        isSignedIn: true,
        profile: {
          gender,
          username,
          avatar,
          isPrivate: is_private,
          about,
          location,
          url,
          isVerified: is_verified,
          isOnboarded: is_onboarded,
          following,
          bookmarks,
          branding,
          email,
          id,
          provider,
          subscription,
          current_contest,
          unique_name,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default userReducer;
