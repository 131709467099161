import { Box, Button, Container, makeStyles, TextField, Typography } from '@material-ui/core';
import ShareIcon from '@material-ui/icons/Share';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { getContests } from '../../actions/promotions';
import { useQuery } from '../../hooks/useQuery';
import ContestFormLink from './ContestFormLink';

const styles = makeStyles((theme) => ({
  containerRoot: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  banner: {
    width: '100%',
    marginBottom: 20,
    marginTop: 21,
  },
  containerBox: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  right: {
    width: 500,
    background: theme.palette.background.paper,
    padding: 20,
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      marginTop: 20,
    },
  },
  left: {
    width: 620,
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  entries: {
    font: 'normal normal normal 24px/32px Roboto',
    letterSpacing: 1.71,
    margin: '10px 0px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
  },
  title: {
    font: 'normal normal bold 32px/43px Roboto',
    letterSpacing: 2.29,
    color: theme.palette.text.primary,
    margin: '10px 0px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
    },
  },
  title2: {
    font: 'normal normal bold 18px/24px Roboto',
    letterSpacing: 1.29,
    color: theme.palette.text.primary,
    margin: '10px 0px',
  },
  qualification: {
    font: 'normal normal normal 16px/20px Roboto',
    letterSpacing: 0,
    color: theme.palette.text.primary,
    marginBottom: 20,
  },
  text: {
    font: 'normal normal normal 16px/20px Roboto',
    letterSpacing: 0,
    color: theme.palette.text.primary,
  },
  description: {
    font: 'normal normal normal 16px/22px Roboto',
    letterSpacing: 0,
    color: theme.palette.text.primary,
  },
  shareAndPlayBox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 10,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  buttonBox: {
    display: 'flex',
    alignContent: 'center',
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'left',
    },
  },
  playBox: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'left',
    },
  },
  share: {
    font: 'normal normal medium 18px/28px Roboto !important',
    color: theme.palette.text.primary,
    marginRight: 20,
    alignContent: 'center',
  },
  fileCopyIcon: {
    cursor: 'pointer',
  },
  backButton: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 10,
    paddingLeft: 10,
    cursor: 'pointer',
  },
  backButtonLabel: {
    fontSize: 16,
    lineHeight: '24px',
    marginLeft: 10,
    display: 'block',
    color: theme.palette.primary.main,
  },
  shareButton: {
    display: 'flex',
    width: 100,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const ContestDetail = ({ match, webView = false, user }) => {
  const classes = styles();
  const inputRefUn = useRef(null);

  // eslint-disable-next-line react/prop-types
  const { contestId } = match.params;

  const contestsData = useQuery('getContests', getContests, {
    variables: [contestId],
  });
  const contest = contestsData?.data?.data?.shift();
  const enterDate = contest?.enter_by_date && new Intl.DateTimeFormat('en-US', { dateStyle: 'medium', timeStyle: 'short', timeZone: 'America/New_York' }).format(new Date(`${contest?.enter_by_date}`));

  return (
    <>
      <Box>
        <Box
          onClick={() => window.history.back()}
          className={classes.backButton}
        >
          <ArrowBackIcon style={{ color: '#37bc98' }} />
          <span className={classes.backButtonLabel}>Back to Contests</span>
        </Box>
      </Box>

      <Container classes={{ root: classes.containerRoot }}>
        {contest?.logo_url && (
          <img src={contest?.logo_url} className={classes.banner} alt="" />
        )}
        <Box className={classes.shareAndPlayBox}>
          <Box className={classes.buttonBox}>
            <Button
              variant="contained"
              color="primary"
              className={classes.shareButton}
              onClick={() => {
                navigator.clipboard.writeText(window.location.href);
                toast.success('Copied to Clipboard.');
              }}
            >
              <span>Share</span>
              <ShareIcon />
            </Button>
          </Box>
          <Box className={classes.playBox}>
            {contest?.enterable && (
              <>
                <span className={classes.share}>Username* (min 5 chars):</span>
                <TextField
                  defaultValue={user?.unique_name}
                  inputRef={inputRefUn}
                  variant="outlined"
                  className={classes.inputText}
                  disabled={Boolean(user?.unique_name)}
                />
              </>
            )}

            <ContestFormLink
              contest={contest}
              style={{
                padding: '15px 30px',
                marginLeft: 20,
              }}
              onSuccess={() => contestsData?.refetch()}
              getUniqueName={() => inputRefUn.current.value}
              label="Play!"
            />
          </Box>
        </Box>
        <Box className={classes.containerBox}>
          <Box className={classes.left}>
            <Typography className={classes.title}>{contest?.name}</Typography>
            {contest?.user_qualification.qualified === false && (
              <Typography className={classes.qualification}>
                <b>{contest?.user_qualification?.message}</b>
              </Typography>
            )}
            <Typography className={classes.description}>{contest?.full_description}</Typography>
            {enterDate && (
              <Typography className={classes.entries}>
                Submission Due By: {enterDate} EDT
              </Typography>
            )}
          </Box>
          {(contest?.prizes_description || contest?.rules_description) && (
            <Box className={classes.right}>
              {contest?.prizes_description && (
                <>
                  <Typography className={classes.title2}>Prizes</Typography>
                  <Typography
                    className={classes.text}
                    dangerouslySetInnerHTML={{ __html: contest?.prizes_description }}
                  />
                </>
              )}
              {contest?.rules_description && (
                <>
                  <Typography className={classes.title2}>Rules</Typography>
                  <Typography
                    className={classes.text}
                    dangerouslySetInnerHTML={{ __html: contest?.rules_description }}
                  />
                </>
              )}
            </Box>
          )}
        </Box>
      </Container>
    </>
  );
};

ContestDetail.propTypes = {
  match: PropTypes.shape(),
  webView: PropTypes.bool,
  user: PropTypes.shape(),
};

function mapStateToProps(state) {
  return {
    user: state.user.profile,
  };
}

export default connect(mapStateToProps)(ContestDetail);
